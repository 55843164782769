<template>
  <div>
    <b-row>
      <b-col>
        <Card :title="'Cabeçalho'">
          Header selector

          <template #card-footer>
            <hr />
            <div class="mb-3 d-flex justify-content-end">
              <b-button
                variant="success"
                @click="open_slider('templatesHeader')"
                >Selecionar</b-button
              >
            </div>
          </template>
        </Card>
      </b-col>
      <b-col>
        <Card :title="'Rodapé'">
          Footer selector
          <template #card-footer>
            <hr />
            <div class="mb-3 d-flex justify-content-end">
              <b-button
                variant="success"
                @click="open_slider('templatesFooter')"
                >Selecionar</b-button
              >
            </div>
          </template>
        </Card>
      </b-col>
      <b-col>
        <Card :title="'Plano de Fundo'"> BG selector</Card>
      </b-col>
    </b-row>
    <Card class="py-3" v-if="model.header_id !== '' && model.footer_id !== ''">
      <EmailEditor />
    </Card>
    <SweetModal
      ref="slider"
      overlay-theme="dark"
      width="80%"
      hide-close-button
      blocking
    >
      <TemplatesSlider :templates="filteredTemplates" :selected="selected" />
      <template #button>
        <div>
          <b-button @click="$refs.slider.close()">Cancelar</b-button>
        </div>
      </template>
    </SweetModal>
  </div>
</template>

<script>
export default {
  props: ["model"],
  data() {
    return {
      selected: "",
      filteredTemplates: [],
    };
  },
  components: {
    EmailEditor: () => import("@/components/EmailEditor"),
    TemplatesSlider: () => import("./TemplatesSlider"),
  },
  computed: {
    templates() {
      return this.$store.state.modelsCustom.templates;
    },
    templatesHeader() {
      return _.filter(this.templates, (t) => {
        return t.type == "header";
      });
    },
    templatesFooter() {
      return _.filter(this.templates, (t) => {
        return t.type == "footer";
      });
    },
  },
  methods: {
    open_slider(type) {
      if (type == "templatesHeader") {
        this.selected = this.model.header_id;
      }
      if (type == "templatesFooter") {
        this.selected = this.model.footer_id;
      }
      this.filteredTemplates = this[type];
      this.$refs.slider.open();
    },
  },
  mounted() {
    this.$store.dispatch("modelsCustom/get_templates", {
      notification_type_id: this.model.notification_type_id,
      application_id: this.model.application_id,
    });
  },
};
</script>

<style>
</style>